<template>
	<CCol lg="12">
		<div
			class="form-group form-group-image d-flex align-items-center mb-2"
			:class="{ 'is-invalid': !isValidImage }"
		>
			<div class="image-link">
				<a
					href=""
					data-test-id="add-image-product-labels"
					@click.prevent="handleUploadImageClick"
				>
					<div v-if="!previewImage && !thumbnail" :class="{ 'image-placeholder': !previewImage }">
						<img src="/assets/images/product-placeholder.svg" alt="Add image" class="mb-1">
						Add image
					</div>
					<img
						v-else
						:src="thumbnail || previewImage"
						class="image-preview"
						alt=""
					>
				</a>
				<CButton
					v-if="thumbnail"
					class="btn-delete-image"
					@click="$emit('delete')"
				>
					<CIcon name="cil-trash" />
				</CButton>
			</div>
			<input
				:ref="uploadButtonRef"
				type="file"
				class="d-none"
				accept="image/png, image/jpeg"
				data-test-id="file-image-product-labels"
				@change="handleFileChange"
			>
			<ul class="image-helper pl-4">
				<li>- Recommended resolution {{ recommendedSize }} px</li>
				<li>- Maximum upload file size is 2MB</li>
				<li>- Supported PNG, JPG, JPEG</li>
			</ul>
		</div>
		<div class="form-group-image-error invalid-feedback mb-3">
			{{ errorImageMessage }}
		</div>
	</CCol>
</template>

<script>
import { PRODUCT_LABEL_TYPES } from '../enums/productLabels';

export default {
	name: 'ProductLabelEditImageForm',

	props: {
		previewImage: {
			type: String,
			default: null,
		},
		thumbnail: {
			type: String,
			default: null,
		},
		isValidImage: {
			type: Boolean,
			default: false,
		},
		isPrimary: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: undefined,
		},
		errorImageMessage: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			uploadButtonRef: 'image-file',
		};
	},

	computed: {
		recommendedSize() {
			switch (this.type) {
				case PRODUCT_LABEL_TYPES.primary:
					return 'W80 x H80';
				case PRODUCT_LABEL_TYPES.secondary:
					return 'W676 x H80';
				case PRODUCT_LABEL_TYPES.frame:
					return 'W1000 x H1000';
				default:
					return '';
			}
		},
	},

	methods: {
		handleUploadImageClick() {
			if (!this.thumbnail) {
				this.$refs[this.uploadButtonRef].click();
			}
		},
		handleFileChange(e) {
			this.$emit('onChange', e);
		},
	},
};
</script>

<style lang="scss" scoped>
	.form-group-image {
		a {
			text-decoration: none;
		}

		&.is-invalid {
			.image-placeholder,
			.image-preview {
				border-color: $color-alert;
			}
		}
	}

	.image-placeholder {
		@include typo-helper-text;

		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: rem(78);
		height: rem(78);
		background-color: $color-gray-100;
		border: 1px solid $color-gray-300;
		border-radius: 2px;
		color: $color-black-45;

		img {
			width: rem(32);
			height: rem(32);
		}
	}

	.image-helper {
		list-style: none;
		margin: 0;

		li {
			@include typo-body-2;

			color: $color-black-45;
		}
	}

	.image-link {
		position: relative;
		display: block;
	}

	.image-preview {
		width: rem(78);
		height: rem(78);
		border: 1px solid $color-gray-300;
		border-radius: 2px;
		object-fit: contain;
	}

	.form-group-image-error {
		display: block;
		visibility: hidden;
	}

	.is-invalid ~ .invalid-feedback {
		visibility: visible;
	}

	.type-secondary {
		.image-placeholder,
		.image-preview {
			width: rem(234);
			height: rem(64);
		}
	}

	.btn-delete-image {
		position: absolute;
		right: rem(8);
		bottom: rem(8);
		width: rem(20);
		height: rem(20);
		box-shadow: none;
		padding: 0;
		border-radius: 2px;
		background-color: $color-black-45;

		svg {
			width: rem(12) !important;
			color: $color-white;
		}
	}
</style>

