<template>
	<div class="product-label-expire">
		<h4 class="mb-1">Label active schedule</h4>
		<p class="typo-body-2 color-black-45 mb-3">The label will be set as active and inactive follow to date and<br>time below. Leave it blank to change label status manually.</p>
		<div class="product-label-expire-fields">
			<div class="mr-3 w-50 d-flex">
				<BaseInputDate
					:value="startDate"
					:is-valid="!v.expiredStartDate.$error"
					:invalid-feedback="startDateErrorMessage"
					label="Start date"
					placeholder="Select date"
					class="product-label-expire-date"
					@input="handleSelectedStartDate"
				>
					<template #append-content>
						<CIcon class="cil-calendar" name="cil-calendar" />
					</template>
				</BaseInputDate>
				<BaseInputTime
					:value="startTime"
					:is-valid="!v.expiredStartTime.$error"
					:invalid-feedback="startTimeErrorMessage"
					label="Start time"
					class="product-label-expire-time"
					@input="handleSelectedStartTime"
				/>
			</div>
			<div class="ml-3 w-50 d-flex">
				<BaseInputDate
					:value="endDate"
					:min-date="startDate"
					:is-valid="!v.expiredEndDate.$error"
					:invalid-feedback="endDateErrorMessage"
					label="End date"
					placeholder="Select date"
					class="product-label-expire-date"
					@input="handleSelectedEndDate"
				>
					<template #append-content>
						<CIcon class="cil-calendar" name="cil-calendar" />
					</template>
				</BaseInputDate>
				<BaseInputTime
					:value="endTime"
					:is-valid="!v.expiredEndTime.$error"
					:invalid-feedback="endTimeErrorMessage"
					label="End time"
					class="product-label-expire-time"
					@input="handleSelectedEndTime"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ProductLabelEditExpire',
	props: {
		startDate: {
			type: Date,
			default: null,
		},
		startTime: {
			type: [String, Number],
			default: null,
		},
		endDate: {
			type: Date,
			default: null,
		},
		endTime: {
			type: [String, Number],
			default: null,
		},
		v: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		startDateErrorMessage() {
			return this.getErrorMessage(this.v.expiredStartDate);
		},
		startTimeErrorMessage() {
			return this.getErrorMessage(this.v.expiredStartTime);
		},
		endDateErrorMessage() {
			return this.getErrorMessage(this.v.expiredEndDate);
		},
		endTimeErrorMessage() {
			return this.getErrorMessage(this.v.expiredEndTime);
		},
	},
	methods: {
		handleSelectedStartDate(value) {
			this.$emit('update:start-date', value);
		},
		handleSelectedStartTime(value) {
			this.$emit('update:start-time', value);
		},
		handleSelectedEndDate(value) {
			this.$emit('update:end-date', value);
		},
		handleSelectedEndTime(value) {
			this.$emit('update:end-time', value);
		},
		getErrorMessage(obj) {
			if (!obj.requiredIf) {
				return this.$t('global.error.required');
			} else if (!obj.timeFormat) {
				return this.$t('global.error.timeInvalidFormat');
			} else if (!obj.after) {
				return this.$t('global.error.timeAfter', { time: this.startTime });
			}

			return null;
		},
	},
};
</script>

<style lang="scss" scoped>
	.product-label-expire {
		// .product-label-expire-fields
		&-fields {
			display: flex;
		}

		// .product-label-expire-date
		&-date {
			width: rem(200);
		}

		// .product-label-expire-time
		&-time {
			margin-left: rem(12);
		}
	}
</style>
