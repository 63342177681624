<template>
	<BaseLoading v-if="edit.isLoading" />
	<ProductBadgeLabelEditForm v-else-if="data.type === PRODUCT_LABEL_TYPES.badge" v-model="data" @submit="handleSubmit" />
	<ProductLabelEditForm
		v-else
		v-model="data"
		@submit="handleSubmit"
	/>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ProductLabelEditForm from '@/components/ProductLabelEditForm.vue';
import ProductBadgeLabelEditForm from '@/components/ProductBadgeLabelEditForm.vue';
import { PRODUCT_LABEL_TYPES } from '../enums/productLabels';

export default {
	name: 'ProductLabelEdit',

	components: {
		ProductLabelEditForm,
		ProductBadgeLabelEditForm,
	},
	data() {
		return {
			data: {},
			id: this.$route.params.id || null,
			PRODUCT_LABEL_TYPES,
		};
	},
	computed: {
		...mapState('productLabels', {
			edit: 'edit',
		}),
		...mapGetters({
			label: 'productLabels/getLabel',
		}),
	},
	watch: {
		label: {
			deep: true,
			handler(label) {
				this.data = label;
			},
		},
	},
	async created() {
		await this.getProductLabel(this.id);

		this.data = this.label;
		this.updatePageTitle();
	},
	methods: {
		...mapActions({
			getProductLabel: 'productLabels/getProductLabel',
			setPageTitle: 'pageTitle/setPageTitle',
		}),

		updatePageTitle() {
			this.setPageTitle({
				title: this.label.name,
				isShow: true,
			});
		},
		handleSubmit() {
			this.updatePageTitle();
		},
	},
};
</script>
