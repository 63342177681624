<template>
	<div class="product-label-skus">
		<h4 class="mb-1">Attached product</h4>
		<p class="typo-body-2 color-black-45 mb-3">List of all SKUs that have been attached with this label.</p>
		<div v-if="$listeners.onAddSkus || skus.length" class="d-flex justify-content-between mb-2">
			<div v-if="skus.length" class="typo-body-2 color-black-70 align-self-end">
				Total {{ skus.length }} SKU(s)
			</div>
			<CButton
				v-if="$listeners.onAddSkus"
				class="ml-auto"
				color="secondary"
				@click="onAddSkus"
			>
				Add SKU
			</CButton>
		</div>
		<template v-if="skus.length">
			<div
				class="sku-list border border-gray-400 rounded p-3 mb-5"
			>
				<div
					v-for="item in skus"
					:key="item"
					class="typo-caption-2 d-inline-block mr-1"
				>
					{{ item }};
				</div>
			</div>
		</template>
		<BaseNoItemBanner
			v-else
			text="There is no sku attached."
		/>
	</div>
</template>

<script>
export default {
	name: 'ProductLabelSkuList',

	props: {
		skus: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		skuList() {
			return this.skus.join('; ');
		},
	},
	methods: {
		onAddSkus() {
			this.$emit('onAddSkus');
		},
	},
};
</script>

<style lang="scss" scoped>
	.sku-list {
		max-height: rem(140);
		overflow-y: auto;
	}
</style>
