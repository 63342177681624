<template>
	<form
		class="main-wrapper mt-4"
		:class="`type-${type}`"
		@submit.prevent="handleSubmit"
	>
		<h4 class="mb-4">Label info</h4>

		<ProductLabelEditImageForm
			:class="`type-${type}`"
			:thumbnail="value.thumbnail"
			:is-valid-image="isValidImage"
			:error-image-message="errorImageMessage"
			:preview-image="previewImage"
			:is-primary="isPrimary"
			class="product-label-image-form"
			@delete="handleDeleteImage"
			@onChange="handleFileChange"
		/>

		<CInput
			v-model.trim="$v.value.name.$model"
			:is-valid="!$v.value.name.$error && null"
			label="Label name"
			placeholder="Type the label name here"
			invalid-feedback="Field Length: 1 - 255 characters"
			data-test-id="edit-label-name"
			class="product-label-name"
			@change="$v.value.name.$touch()"
		/>

		<div class="form-group form-group-switch">
			<label class="toggle-label" for="status">Status</label>
			<CSwitch
				id="status"
				:checked="status"
				variant="3d"
				size="sm"
				class="switch-custom"
				color="success"
				@update:checked="handleUpdateStatus"
			/>
			<span>{{ status ? 'Active' : 'Inactive' }}</span>
		</div>

		<hr>

		<ProductLabelEditExpire
			:start-date="$v.value.expiredStartDate.$model"
			:start-time="$v.value.expiredStartTime.$model"
			:end-date="$v.value.expiredEndDate.$model"
			:end-time="$v.value.expiredEndTime.$model"
			:v="$v.value"
			@update:start-date="handleChangedStartDate"
			@update:start-time="handleChangedStartTime"
			@update:end-date="handleChangedEndDate"
			@update:end-time="handleChangedEndTime"
		/>

		<hr>

		<ProductLabelSkuList :skus="value.skus" />

		<hr>

		<BaseActionPanelStickyFooter
			:disabled-confirm="edit.isLoading"
			content-class="main-wrapper"
			is-edit
			remove-text="Remove label"
			data-test-id="edit-type-product-labels"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: 'ProductLabels'})"
			@onRemove="$refs['modal-remove'].open()"
		/>

		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteProductLabel.bind(null, $route.params.id)"
			title="Remove this label?"
			description="Removing this label will also detach this label from all locations that are using this label such as product label, installment label, and promotion label."
			@onSuccess="$router.push({ name: 'ProductLabelList', query: { type }})"
			@onFailure="handleDeleteFailure"
		/>
		<BaseModalDialog
			ref="modal-remove-dialog"
			title="Unable to remove"
			description="This label is default label and cannot be removed."
		/>
	</form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ProductLabelEditImageForm from '@/components/ProductLabelEditImageForm.vue';
import ProductLabelEditExpire from '@/components/ProductLabelEditExpire.vue';
import ProductLabelSkuList from '@/components/ProductLabelSkuList.vue';
import BaseModalConfirmDelete from '@/components/BaseModalConfirmDelete.vue';
import BaseModalDialog from '@/components/BaseModalDialog.vue';
import { pathOr, convertDateTimeToUTC, validateImageFileName } from '../assets/js/helpers';
import { PRODUCT_LABEL_TYPES, PRODUCT_LABEL_STATUSES, ERROR_REMOVE_DEFAULT_LABEL_KEY } from '../enums/productLabels';
import { timeFormat, afterDateTime } from '../assets/js/validators';

const TWO_MB = 1024 * 1024 * 2;

export default {
	name: 'ProductLabelEditForm',

	validations: {
		value: {
			name: {
				required,
				maxLength: maxLength(255),
			},
			expiredStartDate: {
				requiredIf: requiredIf((vm) => vm.expiredStartTime),
			},
			expiredStartTime: {
				requiredIf: requiredIf((vm) => vm.expiredStartDate),
				timeFormat,
			},
			expiredEndDate: {
				requiredIf: requiredIf((vm) => vm.expiredEndTime),
			},
			expiredEndTime: {
				requiredIf: requiredIf((vm) => vm.expiredEndDate),
				timeFormat,
				after: afterDateTime({
					fromDateKey: 'expiredStartDate',
					fromTimeKey: 'expiredStartTime',
					toDateKey: 'expiredEndDate',
					toTimeKey: 'expiredEndTime',
				}),
			},
		},
	},
	components: {
		ProductLabelEditImageForm,
		ProductLabelEditExpire,
		ProductLabelSkuList,
		BaseModalConfirmDelete,
		BaseModalDialog,
	},
	mixins: [validationMixin],
	props: {
		value: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			file: null,
			previewImage: null,
			isValidImage: true,
			PRODUCT_LABEL_TYPES,
			PRODUCT_LABEL_STATUSES,
			maxFileSize: TWO_MB,
			errorImageMessage: null,
		};
	},
	computed: {
		...mapState('productLabels', {
			edit: 'edit',
		}),

		isPrimary() {
			return this.value.type === PRODUCT_LABEL_TYPES.primary;
		},
		status() {
			const value = pathOr(false, ['status', 'value'])(this.value);
			return value === PRODUCT_LABEL_STATUSES.active.value;
		},
		type() {
			return this.value.type;
		},
		expiredStartAt() {
			if (this.value.expiredStartDate && this.value.expiredStartTime && !this.$v.value.expiredStartTime.$invalid) {
				return convertDateTimeToUTC(this.value.expiredStartDate, this.value.expiredStartTime);
			}
			return '';
		},
		expiredEndAt() {
			if (this.value.expiredEndDate && this.value.expiredEndTime && !this.$v.value.expiredEndTime.$invalid) {
				return convertDateTimeToUTC(this.value.expiredEndDate, this.value.expiredEndTime);
			}
			return '';
		},
	},
	methods: {
		...mapActions({
			updateProductLabel: 'productLabels/updateProductLabel',
			deleteProductLabel: 'productLabels/deleteProductLabel',
			showToast: 'toast/showToast',
		}),

		handleFileChange(e) {
			const file = e.target.files[0];

			const errorMaxFileSize = file.size > this.maxFileSize;
			const errorFileName = !validateImageFileName(file.name);

			if (errorMaxFileSize || errorFileName) {
				this.isValidImage = false;
				this.errorImageMessage = errorMaxFileSize ? this.$t('global.error.maxFileSize', { size: 2 }) : this.$t('components.modalMediaLibrary.error.uploadValidationImageFileName');
			} else {
				this.file = file;
				this.previewImage = URL.createObjectURL(file);
				this.isValidImage = true;
			}
		},
		handleUpdateStatus(value) {
			this.$emit('input', {
				...this.value,
				status: value ? PRODUCT_LABEL_STATUSES.active : PRODUCT_LABEL_STATUSES.inactive,
			});
		},
		handleDeleteImage() {
			this.$emit('input', {
				...this.value,
				thumbnail: null,
			});
		},
		handleDeleteFailure(error) {
			this.$refs['modal-remove'].close();
			const errorKey = error?.response?.data?.error ?? null;

			if (errorKey === ERROR_REMOVE_DEFAULT_LABEL_KEY) {
				this.$refs['modal-remove-dialog'].open();
			}
		},
		async handleSubmit() {
			this.$v.$touch();

			if (!this.file && !this.value.thumbnail) {
				this.isValidImage = false;
				this.errorImageMessage = this.$t('global.error.required');
			}

			if (!this.$v.$invalid && this.isValidImage) {
				const formData = new FormData();
				formData.append('name', this.value.name);

				if (this.file) {
					formData.append('file', this.file);
				}

				formData.append('_method', 'put');
				formData.append('type', this.type);
				formData.append('is_active', this.status ? 1 : 0);
				formData.append('start_at', this.expiredStartAt);
				formData.append('end_at', this.expiredEndAt);

				let toast = {
					content: this.$t('global.successMessage'),
					header: this.$t('global.successMessageTitle'),
					type: 'success',
				};

				try {
					await this.updateProductLabel({
						id: this.$route.params.id,
						formData,
					});

					this.$emit('submit');
				} catch (error) {
					// Handle error code 'invalid_image_name'
					toast = {
						header: this.$t('global.errorMessageTitleEdit'),
						content: error?.response?.data?.message ?? this.$t('global.errorMessage'),
						type: 'danger',
					};
				}

				this.showToast(toast);
			}
		},
		handleChangedStartDate(expiredStartDate) {
			this.$emit('input', {
				...this.value,
				expiredStartDate,
			});
			this.$v.value.expiredStartDate.$reset();
			this.$v.value.expiredEndTime.$reset();
		},
		handleChangedStartTime(expiredStartTime) {
			this.$emit('input', {
				...this.value,
				expiredStartTime,
			});
			this.$v.value.expiredStartTime.$reset();
			this.$v.value.expiredEndTime.$reset();
		},
		handleChangedEndDate(expiredEndDate) {
			this.$emit('input', {
				...this.value,
				expiredEndDate,
			});
			this.$v.value.expiredEndDate.$reset();
		},
		handleChangedEndTime(expiredEndTime) {
			this.$emit('input', {
				...this.value,
				expiredEndTime,
			});
			this.$v.value.expiredEndTime.$reset();
		},
	},
};
</script>

<style lang="scss" scoped>
	.btn-remove {
		margin-right: auto;
	}
</style>

<style lang="scss" scoped>
	hr {
		margin: rem(40) 0;
	}

	.product-label-image-form,
	.product-label-name {
		margin-bottom: rem(32);
	}

	.product-label-image-form {
		padding: 0;
	}
</style>
