<template>
	<div>
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					:title="title"
					hide-close-button
				/>
			</template>
			<template #default>
				<div class="modal-dialog-content">
					{{ description }}
				</div>
			</template>
			<template #footer>
				<CButton
					color="primary"
					@click="close"
				>
					Close
				</CButton>
			</template>
		</CModal>
	</div>
</template>

<script>
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';

export default {
	name: 'BaseModalDialog',

	components: {
		ModalHeaderWrapper,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			isShow: false,
		};
	},
	methods: {
		open() {
			this.isShow = true;
		},
		close() {
			this.isShow = false;
		},
	},
};
</script>
