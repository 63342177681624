<template>
	<form
		class="main-wrapper mt-4"
		:class="`type-${type}`"
		@submit.prevent="handleSubmit"
	>
		<h4 class="mb-4">Label info</h4>
		<CInput
			v-model.trim="$v.value.name.$model"
			:is-valid="!$v.value.name.$error && null"
			label="Label name"
			placeholder="Type the label name here"
			invalid-feedback="Field Length: 1 - 255 characters"
			data-test-id="label-name-product"
			class="product-label-name"
			@change="$v.value.name.$touch()"
		/>

		<BaseDropDown
			v-model="$v.value.fontColor.$model"
			:is-valid="!$v.value.fontColor.$error && null"
			:options="BADGE_LABEL_OPTIONS"
			:searchable="false"
			:allow-empty="false"
			class="select-custom"
			track-by="value"
			label-drop-down="Font Color"
			invalid-feedback="Required Field"
			placeholder="Select Font Color"
			is-custom-color-label-option
			@input="handleSelectFontColor"
		/>

		<div class="badge-input-wrapper">
			<CInput
				v-model.trim="$v.value.titleEN.$model"
				:is-valid="!$v.value.titleEN.$error && null"
				label="Label title (EN)"
				placeholder="Type the label name here"
				invalid-feedback="Field Length: 1 - 255 characters"
				data-test-id="label-name-product"
				class="product-badge-label-name"
				@change="$v.value.titleEN.$touch()"
			/>
			<span class="exam-text">Example : <ProductLabelBadge :label="value.titleEN" :color="fontColorValue" /></span>
		</div>
		<div class="badge-input-wrapper">
			<CInput
				v-model.trim="$v.value.titleTH.$model"
				:is-valid="!$v.value.titleTH.$error && null"
				label="Label title (TH)"
				placeholder="Type the label name here"
				invalid-feedback="Field Length: 1 - 255 characters"
				data-test-id="label-name-product"
				class="product-badge-label-name"
				@change="$v.value.titleTH.$touch()"
			/>
			<span class="exam-text">Example : <ProductLabelBadge :label="value.titleTH" :color="fontColorValue" /></span>
		</div>

		<div class="form-group form-group-switch">
			<label class="toggle-label" for="status">Status</label>
			<CSwitch
				id="status"
				:checked="status"
				variant="3d"
				size="sm"
				class="switch-custom"
				color="success"
				@update:checked="handleUpdateStatus"
			/>
			<span>{{ status ? 'Active' : 'Inactive' }}</span>
		</div>
		<!--hide ไว้ก่อนจาก ticket S7APP-1207-->
		<!-- <div>
			<div class="form-group form-group-switch">
				<label class="toggle-label" for="lob-visibility">LOB visibility</label>
				<CSwitch
					id="lob-visibility"
					:checked="value.lobVisibility"
					variant="3d"
					size="sm"
					class="switch-custom"
					color="success"
					@update:checked="handleLobVisibility"
				/>
				<span>{{ value.lobVisibility ? 'On' : 'Off' }}</span>
			</div>
			<div class="form-group form-group-switch">
				<label class="toggle-label" for="plp-visibility">PLP visibility</label>
				<CSwitch
					id="plp-visibility"
					:checked="value.plpVisibility"
					variant="3d"
					size="sm"
					class="switch-custom"
					color="success"
					@update:checked="handlePlpVisibility"
				/>
				<span>{{ value.plpVisibility ? 'On' : 'Off' }}</span>
			</div>
			<div class="form-group form-group-switch">
				<label class="toggle-label" for="product-family-visibility">Product family visibility</label>
				<CSwitch
					id="product-family-visibility"
					:checked="value.productFamilyVisibility"
					variant="3d"
					size="sm"
					class="switch-custom"
					color="success"
					@update:checked="handleProductFamilyVisibility"
				/>
				<span>{{ value.productFamilyVisibility ? 'On' : 'Off' }}</span>
			</div>
		</div> -->

		<hr>
		<!--Setting label-->
		<template name="setting-label">
			<h4 class="mb-1">Setting Label</h4>
			<p class="typo-body-2 color-black-45 mb-3">The label will be set as active and inactive follow to selected below. Leave it blank to change label status manually.</p>
			<div class="row">
				<div v-for="(option, index) in settingLabelOptions" :key="index" class="col-md-6">
					<CInputRadio
						name="setting-label"
						:label="option.label"
						:value="option.value"
						custom
						:checked="option.value == value.isSpecialVisible"
						@update:checked="handleRadioSettingLabel(option.value)"
					/>
				</div>
			</div>
		</template>
		<hr>
		<ProductLabelEditExpire
			:start-date="$v.value.expiredStartDate.$model"
			:start-time="$v.value.expiredStartTime.$model"
			:end-date="$v.value.expiredEndDate.$model"
			:end-time="$v.value.expiredEndTime.$model"
			:v="$v.value"
			@update:start-date="handleChangedStartDate"
			@update:start-time="handleChangedStartTime"
			@update:end-date="handleChangedEndDate"
			@update:end-time="handleChangedEndTime"
		/>

		<hr>

		<ProductLabelSkuList
			:skus="value.skus"
			@onAddSkus="handleAddSku"
		/>

		<hr>

		<BaseActionPanelStickyFooter
			:disabled-confirm="edit.isLoading"
			content-class="main-wrapper"
			is-edit
			remove-text="Remove label"
			data-test-id="edit-type-product-labels"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: 'ProductLabels'})"
			@onRemove="$refs['modal-remove'].open()"
		/>

		<!-- Modal Section -->

		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteProductLabel.bind(null, $route.params.id)"
			title="Remove this label?"
			description="Removing this label will also detach this label from all locations that are using this label such as product label, installment label, and promotion label."
			@onSuccess="$router.push({ name: 'ProductLabelList', query: { type }})"
			@onFailure="handleDeleteFailure"
		/>
		<BaseModalDialog
			ref="modal-remove-dialog"
			title="Unable to remove"
			description="This label is default label and cannot be removed."
		/>

		<ModalSkuList
			ref="modal-sku"
			title="Add SKU"
			add-button-text="Add"
			:sku-list="value.skus"
			@onConfirm="handleSubmitModal"
		/>
	</form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

import ProductLabelEditExpire from '@/components/ProductLabelEditExpire.vue';
import ProductLabelSkuList from '@/components/ProductLabelSkuList.vue';
import BaseModalConfirmDelete from '@/components/BaseModalConfirmDelete.vue';
import ProductLabelBadge from '@/components/ProductLabelBadge.vue';
import BaseModalDialog from '@/components/BaseModalDialog.vue';
import ModalSkuList from '@/components/ModalSkuList.vue';

import { pathOr, convertDateTimeToUTC } from '../assets/js/helpers';
import { PRODUCT_LABEL_TYPES, PRODUCT_LABEL_STATUSES, ERROR_REMOVE_DEFAULT_LABEL_KEY, BADGE_LABEL_OPTIONS } from '../enums/productLabels';
import { timeFormat, afterDateTime } from '../assets/js/validators';
import { BUNDLE_SETTING_BADGE_OPTIONS } from '../enums/bundleSet';

export default {
	name: 'ProductBadgeLabelEditForm',

	validations: {
		value: {
			name: {
				required,
				maxLength: maxLength(255),
			},
			titleEN: {
				required,
				maxLength: maxLength(255),
			},
			titleTH: {
				required,
				maxLength: maxLength(255),
			},
			fontColor: {
				required,
			},
			expiredStartDate: {
				requiredIf: requiredIf((vm) => vm.expiredStartTime),
			},
			expiredStartTime: {
				requiredIf: requiredIf((vm) => vm.expiredStartDate),
				timeFormat,
			},
			expiredEndDate: {
				requiredIf: requiredIf((vm) => vm.expiredEndTime),
			},
			expiredEndTime: {
				requiredIf: requiredIf((vm) => vm.expiredEndDate),
				timeFormat,
				after: afterDateTime({
					fromDateKey: 'expiredStartDate',
					fromTimeKey: 'expiredStartTime',
					toDateKey: 'expiredEndDate',
					toTimeKey: 'expiredEndTime',
				}),
			},
		},
	},
	components: {
		ProductLabelEditExpire,
		ProductLabelSkuList,
		BaseModalConfirmDelete,
		BaseModalDialog,
		ProductLabelBadge,
		ModalSkuList,
	},
	mixins: [validationMixin],
	props: {
		value: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			PRODUCT_LABEL_TYPES,
			PRODUCT_LABEL_STATUSES,
			BADGE_LABEL_OPTIONS,
			errorImageMessage: null,
			settingLabelOptions: BUNDLE_SETTING_BADGE_OPTIONS,
		};
	},
	computed: {
		...mapState('productLabels', {
			edit: 'edit',
		}),

		fontColorValue() {
			return this.value.fontColor && this.value.fontColor.value;
		},

		isPrimary() {
			return this.value.type === PRODUCT_LABEL_TYPES.primary;
		},
		status() {
			const value = pathOr(false, ['status', 'value'])(this.value);
			return value === PRODUCT_LABEL_STATUSES.active.value;
		},
		type() {
			return this.value.type;
		},
		expiredStartAt() {
			if (this.value.expiredStartDate && this.value.expiredStartTime && !this.$v.value.expiredStartTime.$invalid) {
				return convertDateTimeToUTC(this.value.expiredStartDate, this.value.expiredStartTime);
			}
			return '';
		},
		expiredEndAt() {
			if (this.value.expiredEndDate && this.value.expiredEndTime && !this.$v.value.expiredEndTime.$invalid) {
				return convertDateTimeToUTC(this.value.expiredEndDate, this.value.expiredEndTime);
			}
			return '';
		},
		selectedSkusJoined() {
			if (!this.value.skus || !this.value.skus.length) {
				return '';
			}
			return this.value.skus.join(';');
		},
	},
	created() {
		// convert fontColor value to object
		this.value.fontColor = BADGE_LABEL_OPTIONS.find((badgeLabel) => badgeLabel.value === this.value.fontColor);
	},
	methods: {
		...mapActions({
			updateProductLabel: 'productLabels/updateProductLabel',
			deleteProductLabel: 'productLabels/deleteProductLabel',
			showToast: 'toast/showToast',
		}),
		handleUpdateStatus(value) {
			this.$emit('input', {
				...this.value,
				status: value ? PRODUCT_LABEL_STATUSES.active : PRODUCT_LABEL_STATUSES.inactive,
			});
		},
		handleLobVisibility(value) {
			this.$emit('input', {
				...this.value,
				lobVisibility: value,
			});
		},
		handlePlpVisibility(value) {
			this.$emit('input', {
				...this.value,
				plpVisibility: value,
			});
		},
		handleProductFamilyVisibility(value) {
			this.$emit('input', {
				...this.value,
				productFamilyVisibility: value,
			});
		},
		handleDeleteImage() {
			this.$emit('input', {
				...this.value,
				thumbnail: null,
			});
		},
		handleDeleteFailure(error) {
			this.$refs['modal-remove'].close();
			const errorKey = error?.response?.data?.error ?? null;

			if (errorKey === ERROR_REMOVE_DEFAULT_LABEL_KEY) {
				this.$refs['modal-remove-dialog'].open();
			}
		},
		async handleSubmit() {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				const formData = new FormData();
				formData.append('name', this.value.name);
				formData.append('title_en', this.value.titleEN);
				formData.append('title_th', this.value.titleTH);
				formData.append('font_color', this.value.fontColor?.value);

				formData.append('_method', 'put');
				formData.append('type', this.type);
				formData.append('is_active', this.status ? 1 : 0);
				formData.append('start_at', this.expiredStartAt);
				formData.append('end_at', this.expiredEndAt);

				formData.append('is_lob_visible', this.value.lobVisibility ? 1 : 0);
				formData.append('is_plp_visible', this.value.plpVisibility ? 1 : 0);
				formData.append('is_product_family_visible', this.value.productFamilyVisibility ? 1 : 0);
				formData.append('is_special_visible', this.value.isSpecialVisible ? 1 : 0);

				formData.append('skus', this.selectedSkusJoined);

				let toast = {
					content: this.$t('global.successMessage'),
					header: this.$t('global.successMessageTitle'),
					type: 'success',
				};

				try {
					await this.updateProductLabel({
						id: this.$route.params.id,
						formData,
					});

					this.$emit('submit');
				} catch (error) {
					// Handle error code 'invalid_image_name'
					toast = {
						header: this.$t('global.errorMessageTitleEdit'),
						content: error?.response?.data?.message ?? this.$t('global.errorMessage'),
						type: 'danger',
					};
				}

				this.showToast(toast);
			}
		},
		handleChangedStartDate(expiredStartDate) {
			this.$emit('input', {
				...this.value,
				expiredStartDate,
			});
			this.$v.value.expiredStartDate.$reset();
			this.$v.value.expiredEndTime.$reset();
		},
		handleChangedStartTime(expiredStartTime) {
			this.$emit('input', {
				...this.value,
				expiredStartTime,
			});
			this.$v.value.expiredStartTime.$reset();
			this.$v.value.expiredEndTime.$reset();
		},
		handleChangedEndDate(expiredEndDate) {
			this.$emit('input', {
				...this.value,
				expiredEndDate,
			});
			this.$v.value.expiredEndDate.$reset();
		},
		handleChangedEndTime(expiredEndTime) {
			this.$emit('input', {
				...this.value,
				expiredEndTime,
			});
			this.$v.value.expiredEndTime.$reset();
		},

		handleSelectFontColor(value) {
			this.value.fontColor = value;
		},

		handleAddSku() {
			this.$refs['modal-sku'].open();
		},
		handleSubmitModal(skus = []) {
			this.value.skus = skus;
		},
		handleRadioSettingLabel(value) {
			this.value.isSpecialVisible = value;
		},
	},
};
</script>

<style lang="scss" scoped>
	.btn-remove {
		margin-right: auto;
	}
</style>

<style lang="scss" scoped>
	hr {
		margin: rem(40) 0;
	}

	.product-label-image-form,
	.product-label-name {
		margin-bottom: rem(32);
	}

	.badge-input-wrapper {
		display: flex;
		flex-flow: column nowrap;
		margin-bottom: rem(32);

		.form-group {
			margin-bottom: rem(8);
		}

		.exam-text {
			@include typo-body-2;

			color: $color-black-45;
		}
	}

	.product-badge-label-image-form {
		padding: 0;
	}

</style>
