<template>
	<div>
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
			class="modal-sku-list"
		>
			<template #header-wrapper>
				<ModalHeaderWrapper :title="title" @onClose="close" />
			</template>
			<template #default>
				<CRow>
					<CCol>
						<FormGetProductBySkuList
							ref="form-sku"
							:sku-list="localSKUList"
							:action-button-text="addButtonText"
							@onGetProduct="handleGet"
						>
							<template #no-item>
								<div class="text-center" data-test-id="no-item">
									<div class="d-block">
										<div class="font-weight-bolder color-black-45">
											No SKU yet
										</div>
										<div class="typo-body-2 color-black-45">
											Add "SKU number" in order to set condition.
										</div>
									</div>
								</div>
							</template>
						</FormGetProductBySkuList>
					</CCol>
				</CRow>
			</template>
			<template #footer>
				<BaseModalFooter
					:total-select="localSKUList.length"
					:is-show-clear="true"
					:disabled-confirm="!isListChanged"
					@onCancel="handleCancel"
					@onClear="handleClear"
					@onConfirm="handleSubmit"
				/>
			</template>
		</CModal>
		<BaseModalConfirm
			v-if="needConfirmation"
			ref="modal-confirm"
			:title="titleConfirm"
			:description="descriptionConfirm"
			@onConfirm="handleConfirm"
		/>
	</div>
</template>

<script>
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import FormGetProductBySkuList from '@/components/FormGetProductBySkuList.vue';
import BaseModalFooter from '@/components/BaseModalFooter.vue';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';

export default {
	name: 'ModalSkuList',
	components: {
		ModalHeaderWrapper,
		FormGetProductBySkuList,
		BaseModalFooter,
		BaseModalConfirm,
	},
	props: {
		title: {
			type: String,
			default: 'Add SKU',
		},
		titleConfirm: {
			type: String,
			default: null,
		},
		descriptionConfirm: {
			type: String,
			default: null,
		},
		needConfirmation: {
			type: Boolean,
			default: false,
		},
		skuList: {
			type: Array,
			default: () => [],
		},
		addButtonText: {
			type: String,
			default: 'Add SKU',
		},
	},
	data() {
		return {
			isShow: false,
			isListChanged: false,
			localSKUList: [...this.skuList],
		};
	},
	methods: {
		resetState() {
			this.$refs['form-sku'].resetState();
			this.localSKUList = [...this.skuList];
			this.isListChanged = false;
		},
		open() {
			this.isShow = true;
			this.resetState();
		},
		close() {
			this.isShow = false;
		},
		submit() {
			this.isShow = false;
			this.$emit('onConfirm', this.localSKUList);
		},
		handleGet(skuList) {
			this.localSKUList = skuList;
			this.isListChanged = true;
		},
		handleCancel() {
			this.close();
		},
		handleClear() {
			this.localSKUList = [];
			this.isListChanged = true;
		},
		handleSubmit() {
			if (this.needConfirmation) {
				this.$refs['modal-confirm'].open();
			} else {
				this.submit();
			}
		},
		handleConfirm() {
			this.submit();
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .modal-body {
		padding-bottom: 0;
	}

	::v-deep .list-item {
		min-height: rem(200);
		padding: 0;
		margin: 0;
	}
</style>
